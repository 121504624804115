body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiPaper-root {
  box-shadow: none !important;
}

.background{
  position: relative;
  height: 100vh;
  background-image: url("../public/back.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.cover {
  position: relative;
  background: rgba(0, 48, 107, 0.7);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form {
  background: rgba(255, 255, 255, 0.8);
  height: 600px;
  width: 60%;
}

.hide-on-mobile-devices {
  display: block;
}

@media screen and (max-width: 1000px) {
  .hide-on-mobile-devices {
    display: none;
  }
}